import _defineProperty from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { applyStylesToElement, applyToChildren, calcHeight, getChildrenBoxes } from "./lib";
export var useFixedHeader = function useFixedHeader() {
  var fixedHeaderStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var headerRef = useRef(null);
  var rootRef = useRef(null);
  var bgRef = useRef(null);
  var surveyHeaderRef = useRef(document.getElementById('headerWrap'));
  var position = useCallback(function () {
    if (!headerRef.current || !rootRef.current) return;
    requestAnimationFrame(function () {
      if (!rootRef.current || !headerRef.current) return;
      var boxes = getChildrenBoxes(headerRef.current);
      var headerHeight = parseFloat(calcHeight(boxes)) || 0;

      var _rootRef$current$getB = rootRef.current.getBoundingClientRect(),
          bottom = _rootRef$current$getB.bottom,
          height = _rootRef$current$getB.height,
          headerTop = _rootRef$current$getB.top,
          width = _rootRef$current$getB.width;

      var shouldStick = height > window.innerHeight;
      var translate = -headerTop;
      if (!shouldStick) return;
      var offset = 50;
      surveyHeaderRef.current = surveyHeaderRef.current || document.getElementById('headerWrap');
      var withStickyHeader = surveyHeaderRef.current && surveyHeaderRef.current.classList.toString().search('sticky') > -1;

      if (withStickyHeader) {
        var _surveyHeaderRef$curr = surveyHeaderRef.current.getBoundingClientRect(),
            _height = _surveyHeaderRef$curr.height;

        offset = _height + 50;
        translate += _height;
      }

      var bottomBorder = headerHeight + offset;
      var reposition = headerTop < offset && bottom > bottomBorder;

      var commonStyles = _objectSpread(_objectSpread({}, fixedHeaderStyle), {}, {
        transform: "translateY(".concat(reposition ? translate : 0, "px)"),
        backfaceVisibility: reposition ? 'hidden' : 'initial'
      });

      applyToChildren(headerRef.current, _objectSpread(_objectSpread({}, commonStyles), {}, {
        display: reposition ? 'flex' : 'none',
        zIndex: reposition ? '100' : '0'
      }), !reposition);
      applyStylesToElement(bgRef.current, _objectSpread(_objectSpread({}, commonStyles), {}, {
        display: reposition ? 'initial' : 'none',
        height: "".concat(headerHeight, "px"),
        width: "".concat(width, "px"),
        zIndex: reposition ? '99' : '0'
      }), !reposition);
    });
  }, [fixedHeaderStyle]);
  useEffect(function () {
    position();
    window.addEventListener('scroll', position);
    return function () {
      window.removeEventListener('scroll', position);
    };
  }, [position]);
  return useMemo(function () {
    return {
      headerRef: headerRef,
      rootRef: rootRef,
      bgRef: bgRef
    };
  }, []);
};