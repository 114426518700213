import { getSnapshot } from 'mobx-state-tree';
import { getApiClass, QuestionType } from '@webapp/common/resources/survey';
import css from "./question.css";

var replaceMinMax = function replaceMinMax(tpl, min, max) {
  return tpl.replace(/{min}/, String(min)).replace(/{max}/, String(max));
};

var replacePoints = function replacePoints(tpl, points) {
  return tpl.replace(/{points}/, String(points));
};

export var invalidMessageFactory = function invalidMessageFactory(question) {
  var answeredCount = question.answeredCount,
      answers = question.answers,
      groups = question.groups,
      invalid = question.invalid,
      _question$params = question.params,
      distributeScore = _question$params.distributeScore,
      distributeSurplus = _question$params.distributeSurplus,
      max = _question$params.maxLength,
      min = _question$params.minLength,
      survey = question.survey,
      type = question.type;
  var _survey$info$params$a = survey.info.params.alert,
      amount = _survey$info$params$a.amount,
      distribution = _survey$info$params$a.distribution,
      matrixAmount = _survey$info$params$a.matrixAmount,
      numRange = _survey$info$params$a.numRange;
  var withMax = max !== null;
  var withMin = min !== null;

  switch (type) {
    case QuestionType.SELECT_FEW_IMAGE:
      {
        if (withMax && answeredCount > max || withMin && answeredCount < min) {
          return replaceMinMax(amount, min, max);
        }

        return null;
      }

    case QuestionType.DISTRIBUTE_SCALE:
      {
        var pointsSum = answers.reduce(function (acc, _ref) {
          var value = _ref.response.value;
          return acc + value;
        }, 0);
        var points = parseInt("".concat(distributeScore));

        if (pointsSum !== points && invalid && distributeSurplus) {
          return replacePoints(distribution, points);
        }

        return null;
      }

    case QuestionType.MATRIX_SINGLE_ANSWER:
      {
        var answersNumber = groups.reduce(function (acc, group) {
          return acc + Object.keys(getSnapshot(group.responses)).reduce(function (acc, key) {
            return acc + (group.responses.get(key).response.value ? 1 : 0);
          }, 0);
        }, 0);

        if (withMin && answersNumber < min || withMax && answersNumber > max) {
          return replaceMinMax(matrixAmount, min, max);
        }

        return null;
      }

    case QuestionType.TEST_FEW_OF_LIST:
    case QuestionType.FEW_OF_LIST:
      {
        if (withMin && answers.every(function (a) {
          return a.answeredCount >= min;
        }) || withMax && answers.every(function (a) {
          return a.answeredCount <= max;
        })) {
          return replaceMinMax(amount, min, max);
        }

        return null;
      }

    case QuestionType.MATRIX_FEW_ANSWERS:
      {
        if (withMin && answers.every(function (a) {
          return a.answeredCount >= min;
        }) || withMax && answers.every(function (a) {
          return a.answeredCount <= max;
        })) {
          return replaceMinMax(matrixAmount, min, max);
        }

        return null;
      }

    case QuestionType.NUMBER:
      {
        var api = getApiClass(QuestionType.NUMBER);

        if (!api.validate(question) && (withMin || withMax)) {
          return replaceMinMax(numRange, min, max);
        }

        return null;
      }

    default:
      return null;
  }
};
export var styleToQuestionPosition = {
  right: css.end,
  left: css.start,
  center: css.center
};