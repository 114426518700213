import _slicedToArray from "/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
var msgSignature = '[iFrameSizer]message:';
export var WindowCmd;

(function (WindowCmd) {
  WindowCmd["UpdateSurvey"] = "UPD";
  WindowCmd["SetParentHeight"] = "setParentHeight";
  WindowCmd["HideScroll"] = "hideScroll";
  WindowCmd["ShowScroll"] = "showScroll";
  WindowCmd["SetZoom"] = "setZoom";
  WindowCmd["SetFramePos"] = "setFramePos";
})(WindowCmd || (WindowCmd = {}));

export var handleWindowMessage = function handleWindowMessage(cmdMap) {
  var execCmd = function execCmd(cmd, arg) {
    return cmdMap[cmd] && cmdMap[cmd](arg);
  };

  return function (_ref) {
    var data = _ref.data;

    if (typeof data === 'string' && data.startsWith(msgSignature)) {
      try {
        var payload = JSON.parse(data.split(msgSignature)[1]);

        var _payload = _slicedToArray(payload, 2),
            cmd = _payload[0],
            _arg = _payload[1];

        switch (cmd) {
          case WindowCmd.UpdateSurvey:
          case WindowCmd.SetParentHeight:
            execCmd(cmd, _arg);
            break;

          case WindowCmd.HideScroll:
            document.getElementsByTagName('body')[0].classList.add('hideScroll');
            break;

          case WindowCmd.ShowScroll:
            document.getElementsByTagName('body')[0].classList.remove('hideScroll');
            break;

          case WindowCmd.SetZoom:
            // @ts-ignore
            document.getElementsByTagName('body')[0].style.zoom = _arg || 1;
            break;

          case WindowCmd.SetFramePos:
            var params = JSON.parse(_arg);
            var yOffset = params.box.top <= 0 ? Math.abs(params.box.top) : 0;
            yOffset = Math.min(params.box.height - params.height, yOffset);
            document.documentElement.style.setProperty('--popup-offset-y', "".concat(yOffset, "px"));
            document.documentElement.style.setProperty('--parent-window-height', "".concat(params.height, "px"));
            document.documentElement.style.setProperty('--parent-window-width', "".concat(params.width, "px"));
            break;

          default:
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
};